<template>
    <div class="bg">
        <navbar-vue />
        <router-view />
        <footer-vue />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import FooterVue from './components/Footer.vue';
    import NavbarVue from './components/Navbar.vue';

    export default defineComponent({
        components: { FooterVue, NavbarVue },
    });
</script>

<style lang="scss" scoped>
    .bg {
        background-image: url('./assets/pattern.svg');
        background-repeat: repeat;
    }
</style>
