<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="relative bg-gray-50">
        <main class="lg:relative">
            <div
                class="
                    mx-auto
                    max-w-7xl
                    w-full
                    pt-16
                    pb-20
                    text-center
                    lg:py-48 lg:text-left
                "
            >
                <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                    <h1
                        class="
                            text-4xl
                            tracking-tight
                            font-extrabold
                            text-red-900
                            sm:text-5xl
                            md:text-6xl
                            lg:text-5xl
                            xl:text-6xl
                        "
                    >
                        <span class="block xl:inline">Où la beauté</span>
                        {{ ' ' }}
                        <span class="block text-red-600 xl:inline"
                            >et la qualité</span
                        >
                    </h1>
                    <p
                        class="
                            mt-3
                            max-w-md
                            mx-auto
                            text-lg
                            sm:text-xl
                            md:mt-5 md:max-w-3xl
                            text-gray-400
                            font-bold
                        "
                    >
                        Rime avec la passion et l'amour de la race.
                    </p>
                    <div
                        class="mt-10 sm:flex sm:justify-center lg:justify-start"
                    >
                        <div class="rounded-md shadow">
                            <a
                                href="#"
                                class="
                                    w-full
                                    flex
                                    items-center
                                    justify-center
                                    px-8
                                    py-3
                                    border border-transparent
                                    text-base
                                    font-medium
                                    rounded-md
                                    text-white
                                    bg-red-600
                                    hover:bg-red-700
                                    md:py-4 md:text-lg md:px-10
                                "
                                @click.prevent="
                                    $router.push({ name: 'Puppies' })
                                "
                            >
                                Voir les Frenchies
                            </a>
                        </div>
                        <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                            <a
                                href="#"
                                class="
                                    w-full
                                    flex
                                    items-center
                                    justify-center
                                    px-8
                                    py-3
                                    border border-transparent
                                    text-base
                                    font-medium
                                    rounded-md
                                    text-red-600
                                    bg-white
                                    hover:bg-red-50
                                    md:py-4 md:text-lg md:px-10
                                "
                                @click.prevent="$router.push({ name: 'About' })"
                            >
                                À propos
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="
                    relative
                    w-full
                    h-64
                    sm:h-72
                    md:h-96
                    lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full
                "
            >
                <img
                    class="absolute inset-0 w-full h-full object-contain logo"
                    src="@/assets/logo-gray.png"
                    alt=""
                />
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: 'Hero',
    };
</script>
