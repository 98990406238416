/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBCnWKUGQK7m8dcZRSmNOqbk2GjnZz6oes',
    authDomain: 'frenchiez.firebaseapp.com',
    projectId: 'frenchiez',
    storageBucket: 'frenchiez.appspot.com',
    messagingSenderId: '815216179097',
    appId: '1:815216179097:web:417321ff97de61c1008508',
};
const firebasePlugin = {
    install(app: any) {
        const firebase = initializeApp(firebaseConfig);
        app.config.globalProperties.$db = getFirestore(firebase);
    },
};

createApp(App).use(store).use(firebasePlugin).use(router).mount('#app');
