<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="bg-white">
        <div class="pt-32 overflow-hidden sm:pt-14">
            <div class="bg-gray-800">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="relative pt-48 pb-16 sm:pb-24">
                        <div>
                            <h2
                                id="sale-heading"
                                class="
                                    text-4xl
                                    font-extrabold
                                    tracking-tight
                                    text-white
                                    md:text-5xl
                                "
                            >
                                <p>Reservez le vôtre</p>
                                <p class="mt-2 text-3xl">
                                    À partir de 3,500 $CA
                                </p>
                            </h2>
                            <div class="mt-6 text-base animate-bounce">
                                <a
                                    href="#"
                                    class="font-semibold text-white"
                                    @click.prevent="
                                        $router.push({ name: 'Puppies' })
                                    "
                                    >Ici pour voir nos amours<span
                                        aria-hidden="true"
                                    >
                                        &rarr;</span
                                    ></a
                                >
                            </div>
                        </div>

                        <div
                            class="
                                absolute
                                -top-32
                                left-1/2
                                transform
                                -translate-x-1/2
                                sm:top-6 sm:translate-x-0
                            "
                        >
                            <div
                                class="
                                    ml-24
                                    flex
                                    space-x-6
                                    min-w-max
                                    sm:ml-3
                                    lg:space-x-8
                                "
                            >
                                <div
                                    class="
                                        flex
                                        space-x-6
                                        sm:flex-col sm:space-x-0 sm:space-y-6
                                        lg:space-y-8
                                    "
                                >
                                    <div class="flex-shrink-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/pup1.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/pup2.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    class="
                                        flex
                                        space-x-6
                                        sm:-mt-20
                                        sm:flex-col
                                        sm:space-x-0
                                        sm:space-y-6
                                        lg:space-y-8
                                    "
                                >
                                    <div class="flex-shrink-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/pup6.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/pup12.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    class="
                                        flex
                                        space-x-6
                                        sm:flex-col sm:space-x-0 sm:space-y-6
                                        lg:space-y-8
                                    "
                                >
                                    <div class="flex-shrink-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/new5.jpg"
                                            alt=""
                                        />
                                    </div>

                                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                                        <img
                                            class="
                                                h-64
                                                w-64
                                                rounded-lg
                                                object-cover
                                                md:h-72 md:w-72
                                            "
                                            src="@/assets/pup22.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ToList',
    });
</script>
