
    import ScrollBehavior from '@/behaviors/Scroll';
    import HeroVue from '@/components/Hero.vue';
    import IncentivesVue from '@/components/Incentives.vue';
    import ToListVue from '@/components/ToList.vue';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Home',
        components: {
            HeroVue,
            ToListVue,
            IncentivesVue,
        },
        mounted() {
            ScrollBehavior.toTop();
        },
    });
