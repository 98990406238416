<template>
    <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
            <div class="max-w-2xl mx-auto px-4 lg:max-w-none">
                <div
                    class="
                        mt-16
                        grid grid-cols-1
                        gap-y-10 gap-x-8
                        lg:grid-cols-2
                    "
                >
                    <div
                        v-for="incentive in incentives"
                        :key="incentive.name"
                        class="sm:flex lg:block"
                    >
                        <div class="sm:flex-shrink-0">
                            <img
                                class="w-36 h-36"
                                :src="incentive.imageSrc"
                                alt=""
                            />
                        </div>
                        <div class="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                            <h3 class="font-bold text-gray-900">
                                {{ incentive.name }}
                            </h3>
                            <p class="mt-2 text-gray-500">
                                {{ incentive.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const incentives = [
        {
            name: 'Chiots en santé',
            imageSrc: require('@/assets/incent1.svg'),
            description:
                "Vaccins, micropuces, examens de santé, nos frenchies sont en pleine forme, c'est réconfortant pour vous et pour le chiot aussi!",
        },
        {
            name: `Paix d'esprit`,
            imageSrc: require('@/assets/incent2.svg'),
            description:
                'Nous sommes là pour répondre à vos questions, nous voulons avant tout que nos frenchies partent chez vous en sécurité donc nous avons à coeur vos intérogations!',
        },
    ];

    export default {
        name: 'incentives',
        setup() {
            return {
                incentives,
            };
        },
    };
</script>
