<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <footer class="bg-white">
        <div
            class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8"
        >
            <nav
                class="-mx-5 -my-2 flex flex-wrap justify-center"
                aria-label="Footer"
            >
                <div
                    v-for="item in navigation.main"
                    :key="item.name"
                    class="px-5 py-2"
                >
                    <a
                        href="#"
                        class="text-base text-gray-500 hover:text-gray-900"
                        @click.prevent="$router.push({ name: item.href })"
                    >
                        {{ item.name }}
                    </a>
                </div>
            </nav>
            <div class="mt-8 flex justify-center space-x-6">
                <a
                    v-for="item in navigation.social"
                    :key="item.name"
                    :href="item.href"
                    target="_blank"
                    class="text-gray-400 hover:text-gray-500"
                >
                    <span class="sr-only">{{ item.name }}</span>
                    <component
                        :is="item.icon"
                        class="h-6 w-6"
                        aria-hidden="true"
                    />
                </a>
            </div>
            <p class="mt-8 text-center text-base text-gray-400">
                &copy; {{ new Date().getFullYear() }} 94-Frenchiez, tous droits
                réservés
            </p>
        </div>
    </footer>
</template>

<script>
    import { defineComponent, h } from 'vue';

    const navigation = {
        main: [
            { name: 'Accueil', href: 'Home' },
            { name: 'Nos Frenchies', href: 'Puppies' },
            { name: 'À propos', href: 'About' },
            { name: 'Contact', href: 'Contact' },
        ],
        social: [
            {
                name: 'Facebook',
                href: 'https://www.facebook.com/94-FrenchieZ-102398994906822',
                icon: defineComponent({
                    render: () =>
                        h('svg', { fill: '#4267B2', viewBox: '0 0 24 24' }, [
                            h('path', {
                                'fill-rule': 'evenodd',
                                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                                'clip-rule': 'evenodd',
                            }),
                        ]),
                }),
            },
        ],
    };

    export default {
        name: 'Footer',
        setup: () => {
            return {
                navigation,
            };
        },
    };
</script>
