<template>
    <Popover class="relative bg-white shadow" v-slot="{ close }">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <div
                class="
                    flex
                    justify-between
                    items-center
                    py-6
                    md:justify-start md:space-x-10
                "
            >
                <div class="flex justify-start lg:w-0 lg:flex-1">
                    <a href="#" @click.prevent="$router.push({ name: 'Home' })">
                        <span class="sr-only">Logo</span>
                        <img
                            class="h-12 w-auto sm:h-14 logo"
                            src="@/assets/logo-gray.png"
                            alt="logo"
                        />
                    </a>
                </div>
                <div class="-mr-2 -my-2 md:hidden">
                    <PopoverButton
                        class="
                            bg-white
                            rounded-md
                            p-2
                            inline-flex
                            items-center
                            justify-center
                            text-gray-400
                            hover:text-gray-500 hover:bg-gray-100
                            focus:outline-none
                            focus:ring-2
                            focus:ring-inset
                            focus:ring-indigo-500
                        "
                    >
                        <span class="sr-only">Open menu</span>
                        <MenuIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                </div>
                <div as="nav" class="hidden md:flex space-x-10">
                    <a
                        v-for="(nav, index) in navigation.main"
                        :key="index"
                        href="#"
                        :class="[
                            'text-base font-medium text-gray-500 hover:text-gray-900 ',
                            isRouteActive(nav.href)
                                ? 'text-red-500 font-semibold'
                                : '',
                        ]"
                        @click.prevent="$router.push({ name: nav.href })"
                    >
                        {{ nav.name }}
                    </a>
                </div>
                <div
                    class="
                        hidden
                        md:flex
                        items-center
                        justify-end
                        md:flex-1
                        lg:w-0
                        space-x-6
                    "
                >
                    <a
                        v-for="item in navigation.social"
                        :key="item.name"
                        :href="item.href"
                        target="_blank"
                        class="text-gray-400 hover:text-gray-500"
                    >
                        <span class="sr-only">{{ item.name }}</span>
                        <component
                            :is="item.icon"
                            class="h-6 w-6"
                            aria-hidden="true"
                        />
                    </a>
                </div>
            </div>
        </div>

        <transition
            enter-active-class="duration-200 ease-out"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <PopoverPanel
                focus
                class="
                    absolute
                    top-0
                    inset-x-0
                    z-50
                    p-2
                    transition
                    transform
                    origin-top-right
                    md:hidden
                "
            >
                <div
                    class="
                        rounded-lg
                        shadow-lg
                        ring-1 ring-black ring-opacity-5
                        bg-white
                        divide-y-2 divide-gray-50
                    "
                >
                    <div class="pt-5 pb-6 px-5">
                        <div class="flex items-center justify-between">
                            <div>
                                <img
                                    class="h-12 w-auto"
                                    src="@/assets/logo-gray.png"
                                    alt="logo"
                                />
                            </div>
                            <div class="-mr-2">
                                <PopoverButton
                                    class="
                                        bg-white
                                        rounded-md
                                        p-2
                                        inline-flex
                                        items-center
                                        justify-center
                                        text-gray-400
                                        hover:text-gray-500 hover:bg-gray-100
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-inset
                                        focus:ring-indigo-500
                                    "
                                >
                                    <span class="sr-only">Close menu</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </PopoverButton>
                            </div>
                        </div>
                        <div class="mt-6">
                            <nav class="grid gap-y-8">
                                <a
                                    v-for="item in features"
                                    :key="item.name"
                                    :href="item.href"
                                    class="
                                        -m-3
                                        p-3
                                        flex
                                        items-center
                                        rounded-md
                                        hover:bg-gray-50
                                    "
                                >
                                    <component
                                        :is="item.icon"
                                        class="
                                            flex-shrink-0
                                            h-6
                                            w-6
                                            text-indigo-600
                                        "
                                        aria-hidden="true"
                                    />
                                    <span
                                        class="
                                            ml-3
                                            text-base
                                            font-medium
                                            text-gray-900
                                        "
                                    >
                                        {{ item.name }}
                                    </span>
                                </a>
                            </nav>
                        </div>
                    </div>
                    <div class="py-6 px-5 space-y-6">
                        <div class="grid grid-cols-2 gap-y-4 gap-x-8">
                            <a
                                v-for="(nav, index) in navigation.main"
                                :key="index"
                                href="#"
                                :class="[
                                    'text-base font-medium text-gray-900 hover:text-gray-700 ',
                                    isRouteActive(nav.href)
                                        ? 'text-red-500 font-semibold'
                                        : '',
                                ]"
                                @click.prevent="
                                    $router.push({ name: nav.href });
                                    close();
                                "
                            >
                                {{ nav.name }}
                            </a>
                        </div>
                        <div class="w-full flex justify-center space-x-6">
                            <a
                                v-for="item in navigation.social"
                                :key="item.name"
                                :href="item.href"
                                class="text-gray-400 hover:text-gray-500"
                            >
                                <span class="sr-only">{{ item.name }}</span>
                                <component
                                    :is="item.icon"
                                    class="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script lang="ts">
    import {
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
    } from '@headlessui/vue';
    import { MenuIcon, XIcon } from '@heroicons/vue/outline';
    import { ChevronDownIcon } from '@heroicons/vue/solid';
    import { defineComponent, h } from 'vue';

    const navigation = {
        main: [
            { name: 'Accueil', href: 'Home' },
            { name: 'Nos Frenchies', href: 'Puppies' },
            { name: 'À propos', href: 'About' },
            { name: 'Contact', href: 'Contact' },
        ],
        social: [
            {
                name: 'Facebook',
                href: 'https://www.facebook.com/94-FrenchieZ-102398994906822',
                icon: defineComponent({
                    render: () =>
                        h('svg', { fill: '#4267B2', viewBox: '0 0 24 24' }, [
                            h('path', {
                                'fill-rule': 'evenodd',
                                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                                'clip-rule': 'evenodd',
                            }),
                        ]),
                }),
            },
        ],
    };

    export default defineComponent({
        name: 'Navbar',
        components: {
            Popover,
            PopoverButton,
            PopoverGroup,
            PopoverPanel,
            ChevronDownIcon,
            MenuIcon,
            XIcon,
        },
        methods: {
            isRouteActive(routeName: string): boolean {
                return this.$route.name === routeName;
            },
        },
        setup(): any {
            return {
                navigation,
            };
        },
    });
</script>

<style lang="scss" scoped>
    // .logo {
    //     filter: invert(51%) sepia(68%) saturate(7354%) hue-rotate(341deg)
    //         brightness(107%) contrast(88%);
    // }
</style>
